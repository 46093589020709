import React from 'react';
import { Box, Typography, Container, CssBaseline } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useStaticQuery, graphql } from 'gatsby';

import ThemeProviderWrapper from '../theme/themeProvider';
import Logo from '../components/general/logoSign';
import StaticPageFooter from '../layout/staticPageFooter';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function StatusComingSoon() {

    const data = useStaticQuery(graphql`
        query {
            file(relativePath: {eq: "coming-soon.svg"}) {
                publicURL
            }
        }
    `);

    return (
        <ThemeProviderWrapper>
            <CssBaseline />
            <title>Status - Coming Soon</title>
            <MainContent>
                <Container maxWidth="md" sx={{ mt:3 }}>
                    <Logo />
                    <Box textAlign="center" mb={3}>
                        <Container maxWidth="md">
                        <Typography variant="h1" sx={{ mt: 4, mb: 2 }}>
                            Coming Soon
                        </Typography>
                        <Typography
                            variant="h3"
                            color="text.secondary"
                            fontWeight="normal"
                            sx={{ mb: 4 }}
                        >
                            We're still working on this feature please check back soon, or speak to your account manager about when the feature will be available.
                        </Typography>
                        </Container>
                        <img alt="Coming Soon" height={200} src={data.file.publicURL} />
                    </Box>
                    <StaticPageFooter />
                </Container>
            </MainContent>
        </ThemeProviderWrapper>
    );
}

export default StatusComingSoon;
